import React from 'react'
import { cn } from 'lib/utils'
import {
  Activity,
  AlertCircle,
  AlignHorizontalDistributeCenter,
  AlignHorizontalDistributeEnd,
  AlignHorizontalDistributeStart,
  Asterisk,
  Bell,
  Bold,
  BookOpen,
  BookText,
  Calendar,
  Check,
  ChefHat,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Code2,
  Contact,
  Copy,
  DotIcon,
  Download,
  Equal,
  Expand,
  ExternalLink,
  Eye,
  File,
  FileText,
  GraduationCap,
  Heart,
  Image,
  Info,
  Italic,
  Link,
  List,
  ListOrdered,
  ListTree,
  Loader2,
  LucideIcon,
  Menu,
  MessageCircleHeart,
  Minus,
  MoreHorizontal,
  PackageOpen,
  Pencil,
  PenSquare,
  PieChart,
  PlayCircle,
  PlaySquare,
  Plus,
  Redo2,
  Search,
  ShoppingCart,
  SlidersHorizontal,
  Strikethrough,
  Tags,
  Trash2,
  Undo2,
  UploadCloud,
  X,
} from 'lucide-react'
import { Account, Arrow, Facebook, Home, NoAssets, Play, Settings } from 'components/icons'
import { NoFavoriteResources } from 'components/icons/NoFavoriteResources'

const STROKE_WIDTH = 2
const DEFAULT_SIZE = 24

export type IconProps = {
  color?: string
  size?: number
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  className?: string
}

const createLucideIcon =
  ({ Icon }: { Icon: LucideIcon }) =>
  ({ size = DEFAULT_SIZE, ...props }: IconProps) => (
    <Icon
      size={size}
      className={cn('stroke-secondary-900', props.onClick && 'cursor-pointer')}
      strokeWidth={STROKE_WIDTH}
      {...props}
    />
  )

const createSvgIcon =
  ({ Icon }: { Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element }) =>
  ({ size = DEFAULT_SIZE, ...props }: IconProps) => (
    <Icon
      width={size}
      height={size}
      className={cn('text-secondary-900', props.onClick && 'cursor-pointer')}
      strokeWidth={STROKE_WIDTH}
      {...props}
    />
  )

export default {
  // Lucide
  X: createLucideIcon({ Icon: X }),
  Activity: createLucideIcon({ Icon: Activity }),
  AlertCircle: createLucideIcon({ Icon: AlertCircle }),
  Bell: createLucideIcon({ Icon: Bell }),
  BookOpen: createLucideIcon({ Icon: BookOpen }),
  ChevronLeft: createLucideIcon({ Icon: ChevronLeft }),
  Download: createLucideIcon({ Icon: Download }),
  ShoppingCart: createLucideIcon({ Icon: ShoppingCart }),
  SlidersHorizontal: createLucideIcon({ Icon: SlidersHorizontal }),
  Loader2: createLucideIcon({ Icon: Loader2 }),
  Calendar: createLucideIcon({ Icon: Calendar }),
  Copy: createLucideIcon({ Icon: Copy }),
  GraduationCap: createLucideIcon({ Icon: GraduationCap }),
  Equal: createLucideIcon({ Icon: Equal }),
  ChevronRight: createLucideIcon({ Icon: ChevronRight }),
  ChevronDown: createLucideIcon({ Icon: ChevronDown }),
  ChevronUp: createLucideIcon({ Icon: ChevronUp }),
  DotIcon: createLucideIcon({ Icon: DotIcon }),
  Search: createLucideIcon({ Icon: Search }),
  Asterisk: createLucideIcon({ Icon: Asterisk }),
  PlaySquare: createLucideIcon({ Icon: PlaySquare }),
  Check: createLucideIcon({ Icon: Check }),
  Image: createLucideIcon({ Icon: Image }),
  Heart: createLucideIcon({ Icon: Heart }),
  Menu: createLucideIcon({ Icon: Menu }),
  PieChart: createLucideIcon({ Icon: PieChart }),
  ListTree: createLucideIcon({ Icon: ListTree }),
  Tags: createLucideIcon({ Icon: Tags }),
  ExternalLink: createLucideIcon({ Icon: ExternalLink }),
  Trash2: createLucideIcon({ Icon: Trash2 }),
  Pencil: createLucideIcon({ Icon: Pencil }),
  Plus: createLucideIcon({ Icon: Plus }),
  Bold: createLucideIcon({ Icon: Bold }),
  Italic: createLucideIcon({ Icon: Italic }),
  Info: createLucideIcon({ Icon: Info }),
  Strikethrough: createLucideIcon({ Icon: Strikethrough }),
  Minus: createLucideIcon({ Icon: Minus }),
  ListOrdered: createLucideIcon({ Icon: ListOrdered }),
  List: createLucideIcon({ Icon: List }),
  Link: createLucideIcon({ Icon: Link }),
  Code2: createLucideIcon({ Icon: Code2 }),
  Undo2: createLucideIcon({ Icon: Undo2 }),
  Redo2: createLucideIcon({ Icon: Redo2 }),
  Expand: createLucideIcon({ Icon: Expand }),
  AlignHorizontalDistributeStart: createLucideIcon({ Icon: AlignHorizontalDistributeStart }),
  AlignHorizontalDistributeCenter: createLucideIcon({ Icon: AlignHorizontalDistributeCenter }),
  AlignHorizontalDistributeEnd: createLucideIcon({ Icon: AlignHorizontalDistributeEnd }),
  Eye: createLucideIcon({ Icon: Eye }),
  PlayCircle: createLucideIcon({ Icon: PlayCircle }),
  PackageOpen: createLucideIcon({ Icon: PackageOpen }),
  MessageCircleHeart: createLucideIcon({ Icon: MessageCircleHeart }),
  PenSquare: createLucideIcon({ Icon: PenSquare }),
  File: createLucideIcon({ Icon: File }),
  FileText: createLucideIcon({ Icon: FileText }),
  BookText: createLucideIcon({ Icon: BookText }),
  Contact: createLucideIcon({ Icon: Contact }),
  UploadCloud: createLucideIcon({ Icon: UploadCloud }),
  MoreHorizontal: createLucideIcon({ Icon: MoreHorizontal }),
  ChefHat: createLucideIcon({ Icon: ChefHat }),

  // Custom
  Account: createSvgIcon({ Icon: Account }),
  Arrow: createSvgIcon({ Icon: Arrow }),
  Home: createSvgIcon({ Icon: Home }),
  Facebook: createSvgIcon({ Icon: Facebook }),
  NoAssets: createSvgIcon({ Icon: NoAssets }),
  Play: createSvgIcon({ Icon: Play }),
  Settings: createSvgIcon({ Icon: Settings }),
  NoFavoriteResources: createSvgIcon({ Icon: NoFavoriteResources }),
}
